/* AboutSection.css - Fully Responsive Design */
.about-section {
  position: relative;
  width: 100%;
  min-height: 90vh;
  max-height: fit-content;
  background-image: url('../../assets/images/about99.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.about-overlay {
  position: relative;
  z-index: 2;
  width: 90%; /* Responsive width */
  max-width: 800px;
  padding: 2rem;
  box-sizing: border-box;
}

.about-title {
  font-size: 5rem;
  font-weight: bold;
  color: #FFA726;
  margin-bottom: 2rem;
  text-transform: uppercase;
  word-wrap: break-word;
}

.about-text {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #fff;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  word-wrap: break-word;
  hyphens: auto;
}

.read-more-btn {
  background-color: #FFA726;
  color: #000;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.read-more-btn:hover {
  background-color: #FF9800;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .about-section {
    min-height: 80vh;
    padding: 1.5rem;
  }
  
  .about-overlay {
    width: 95%;
    padding: 1.5rem;
  }
  
  .about-title {
    font-size: 4.5rem;
  }
  
  .about-text {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .about-section {
    min-height: 70vh;
    padding: 1.2rem;
  }
  
  .about-overlay {
    width: 100%;
    padding: 1.2rem;
  }
  
  .about-title {
    font-size: 4rem;
  }
  
  .about-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .about-section {
    min-height: 60vh;
    padding: 1rem;
  }
  
  .about-title {
    font-size: 3.5rem;
  }
  
  .about-text {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .about-section {
    min-height: 50vh;
    padding: 0.8rem;
  }
  
  .about-overlay {
    padding: 1rem;
  }
  
  .about-title {
    font-size: 3rem;
  }
  
  .about-text {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .read-more-btn {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .about-section {
    min-height: 40vh;
    padding: 0.5rem;
  }
  
  .about-overlay {
    padding: 0.8rem;
  }
  
  .about-title {
    font-size: 2.5rem;
  }
  
  .about-text {
    font-size: 0.85rem;
    line-height: 1.5;
  }
  
  .read-more-btn {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 375px) {
  .about-section {
    min-height: 35vh;
    padding: 0.3rem;
  }
  
  .about-title {
    font-size: 2rem;
  }
  
  .about-text {
    font-size: 0.8rem;
  }
}