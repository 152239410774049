/* PlayerCard.css - Responsive Design */
.player-card {
    transform-origin: center;
    backface-visibility: hidden;
    will-change: transform;
    width: 300px; /* Default width */
    font-size: 16px; /* Default font size */
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 1200px) {
    .player-card {
      width: 280px;
      font-size: 15px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .player-card {
      width: 250px;
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .player-card {
      width: 220px;
      font-size: 13px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .player-card {
      width: 180px;
      font-size: 12px;
    }
  }
  
  /* Existing styles remain the same */
  .player-card:hover .player-image {
    transform: scale(1.05);
  }
  
  .player-image {
    transition: transform 0.5s ease-in-out;
  }
  
  .shimmer {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 0.3s ease-in forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Scrollbar styles */
  .player-card-content {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  }
  
  .player-card-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .player-card-content::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .player-card-content::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 3px;
  }
  
  .player-card:focus-within {
    outline: 2px solid #60A5FA;
    outline-offset: 2px;
  }
  
  /* Reduced motion support */
  @media (prefers-reduced-motion: reduce) {
    .player-card,
    .player-image,
    .fade-in {
      animation: none;
      transition: none;
    }
  }