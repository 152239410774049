/* Navbar Specific Styles */
.navbar {
    background-color: #1e1c1c !important;
    padding-left: 1rem;
    padding-right: 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .siznav {
    align-items: left;
    height: 60px;
    width: 90px;
    object-fit: contain;
    margin-right: auto;
  }
  
  .navbar-toggler {
    background-color: rgba(255,255,255,0.1);
    border: none;
  }
  
  .navbar-toggler-icon {
    filter: invert(1);
  }
  
  .navbar-collapse {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .navbar-nav {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }
  
  .nav-item {
    margin: 0 0rem;
  }
  
  .nav-link {
    color: white !important;
    transition: color 0.3s ease;
    text-decoration: none;
  }
  
  .nav-link:hover {
    color: orange !important;
  }
  
  /* Social Media Links in Navbar */
  .social-media-links {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
  }
  
  .social-media-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-media-links a:hover {
    color: orange;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 991px) {
    .navbar {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    .navbar-collapse {
      background-color: #1e1c1c;
      position: fixed;
      top: 60px;
      left: -100%;
      width: 100%;
      height: calc(100vh - 60px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: left 0.3s ease-in-out;
      z-index: 999;
    }
  
    .navbar-collapse.show {
      left: 0;
    }
  
    .navbar-nav {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-right: 0;
    }
  
    .nav-item {
      width: 100%;
      text-align: center;
      margin: 1rem 0;
    }
  
    .nav-link {
      display: block;
      padding: 0.5rem 0;
    }
  
    .social-media-links {
      justify-content: center;
      margin-top: 2rem;
      gap: 3rem; /* Increased spacing between social icons */
    }
  
    /* Significantly enlarged social media icons for mobile menu */
    .social-media-links a {
      font-size: 4rem; /* Much larger icons */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media (max-width: 576px) {
    .siznav {
      height: 50px;
      width: 70px;
    }
  
    .social-media-links a {
      font-size: 3.5rem; /* Slightly smaller for very small screens */
    }
  }
  
  /* Additional Utility Styles */
  .padnav {
    padding-left: 1rem;
  }
  
  /* Ensure full-width navbar */
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
  }