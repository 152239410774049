/* Match Card Specific Styles */
.card {
    width: 18rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}

.card-img-top {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.card-body {
    padding: 1rem;
    color: black;
}

.card-title {
    color: black;
    margin-bottom: 0.5rem;
}

.card-text {
    color: black;
    margin-bottom: 0.75rem;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: all 0.15s ease-in-out;
}

.btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

/* Animations */
.anim {
    transition: transform 0.7s ease-in-out;
}

.anim:hover {
    transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .card {
        width: 100%;
        max-width: 300px;
        margin: 0 auto 1rem;
    }
}