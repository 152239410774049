/* FixturesSection.css - Responsive Grid Layout */
.center1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem 1rem;
    width: 100%;
  }
  
  .titlev {
    font-size: 3rem;
    font-weight: 700;
    color: black;
    letter-spacing: 5px;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .gridlay {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 120px;
    padding: 15px;
    justify-content: center; /* Ensure horizontal centering of the entire grid */
    justify-items: center; /* Center individual grid items */
    width: 100%;
    max-width: 1200px; /* Limit maximum width */
    margin: 0 auto; /* Center the grid container itself */
  }
  
  /* Ensure cards are center-aligned */
  .gridlay > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Take full width of grid cell */
  }
  
  .schedule-header {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
  
  .schedule-header h3 {
    color: black;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .fixtures-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  
  /* Responsive Adjustments */
  @media only screen and (min-width: 1200px) {
    .gridlay {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for large devices */
      gap: 120px;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .gridlay {
      gap: 80px;
    }
    
    .titlev {
      font-size: 2.7rem;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .gridlay {
      gap: 60px;
      padding: 10px;
    }
    
    .titlev {
      font-size: 2.5rem;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .center1 {
      padding: 1rem;
    }
    
    .titlev {
      font-size: 2rem;
      letter-spacing: 3px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .gridlay {
      grid-template-columns: repeat(1, 1fr); /* 1 column for small devices */
      gap: 30px;
      padding: 5px;
    }
    
    .titlev {
      font-size: 1.8rem;
      letter-spacing: 2px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .gridlay {
      gap: 20px;
    }
    
    .titlev {
      font-size: 1.6rem;
      letter-spacing: 1px;
    }
  }