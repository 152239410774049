/* SponsorsSection.css - Enhanced Responsive Design */
.sponsors {
  background-color: #000;
  min-height: 100vh;
  padding: 4rem 0;
  width: 100%;
}

.title5 {
  color: white;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 15px;
  text-transform: uppercase;
  margin-bottom: 3rem;
  text-align: center;
}

.title4 {
  color: white;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 3rem 0;
  text-align: center;
}

.card2 {
  background: transparent;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.card2:hover {
  transform: scale(1.05);
}

.card2 img {
  max-width: 300px;
  height: auto;
  filter: brightness(1.2);
}

/* Title sponsor specific styling */
.title-sponsor {
  text-align: center;
}

.title-sponsor .card2 img {
  max-width: 400px;
}

/* Associate sponsors container */
.associate-sponsor-section {
  text-align: center;
}

.associate-sponsors {
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

/* Comprehensive Responsive Adjustments */
@media (max-width: 1200px) {
  .title5 {
    font-size: 3.5rem;
    letter-spacing: 12px;
  }
  
  .title4 {
    font-size: 2.25rem;
    letter-spacing: 4px;
  }
  
  .card2 img {
    max-width: 280px;
  }
  
  .title-sponsor .card2 img {
    max-width: 350px;
  }
}

@media (max-width: 992px) {
  .title5 {
    font-size: 3.25rem;
    letter-spacing: 10px;
  }
  
  .title4 {
    font-size: 2rem;
    letter-spacing: 3px;
  }
  
  .card2 img {
    max-width: 250px;
  }
  
  .title-sponsor .card2 img {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .title5 {
    font-size: 3rem;
    letter-spacing: 8px;
  }
  
  .title4 {
    font-size: 2rem;
    letter-spacing: 3px;
  }
  
  .associate-sponsors {
    flex-direction: column;
    gap: 2rem;
  }
  
  .card2 img {
    max-width: 220px;
  }
  
  .title-sponsor .card2 img {
    max-width: 250px;
  }
}

@media (max-width: 576px) {
  .title5 {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }
  
  .title4 {
    font-size: 1.75rem;
    letter-spacing: 2px;
  }
  
  .card2 img {
    max-width: 180px;
  }
  
  .title-sponsor .card2 img {
    max-width: 200px;
  }
  
  .associate-sponsors {
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .title5 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  
  .title4 {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  
  .card2 img {
    max-width: 150px;
  }
  
  .title-sponsor .card2 img {
    max-width: 180px;
  }
  
  .associate-sponsors {
    gap: 1rem;
  }
}